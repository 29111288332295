import React from 'react';
import { Nav } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';

/**
 * Assets
 */
import './styles/_index.scss';



function getOffset( el ) {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const StickyNav = (props) => {
  const areamenus = props.links
  const scrollHandle = (event) => {
    event.preventDefault();
    const offestTop = getOffset(document.querySelector(event.target.getAttribute('href'))).top;
    const stickyHeight = document.querySelector('.sticky-nav').clientHeight;
    window.scrollTo({
      top: offestTop - stickyHeight,
      behavior: 'smooth'
    });
  }


  return (
    <div className="sticky-nav">
      <Scrollspy
        items={["details", "video", "map"]}
        offset={-50}

        componentTag={Nav}
        currentClassName="is-active"
      >
          {areamenus.map((link, ind) => (
            <Nav.Link key={ind} href={`#${link.Title.toLowerCase().replace(/\s/g, '')}`} onClick={link.isSpy ? scrollHandle : ''}>{link.Title}</Nav.Link>
          ))}
      </Scrollspy>
    </div>
  )
}

export default StickyNav;
