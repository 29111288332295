import React, { useEffect } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import Select from 'react-select';
import LocRatingMap from '@components/map/loc-rating-map';
import { MapMarkerInfoBox } from '@components/map/map-marker';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import useDeviceDetect from '@blocks/detect-device/detect-device';
import Video from '@components/video/video';
import Marker from '@components/marker/marker';

const PropertyLocation = (props) => {
  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.data.Latitude;        
  mapItem['lng']  = props.data.Longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.data.Title + props.data.Address, mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

    const data = useStaticQuery(graphql`
        query {
        video: file(relativePath: { eq: "video2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    const styles = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0,
            outline: 0,
            boxShadow: 'none',
            color: '#5D9272'
        }),
        option: (styles) => ({
            ...styles
        }),
        placeholder: () => null,
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#5D9272"
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#5D9272',
        }),
        indicatorSeparator: () => null,
        singleValue: styles => ({
            ...styles,
            color: '#5D9272',
        })
    }

    let AOS;
    useEffect(() => {
      const AOS = require("aos");
      AOS.init({
        once: true,
        disable: 'mobile'
      });
    }, [])

 
    const image_url = props.Areaguide.Tile_Image.internal.description ? props.Areaguide.Tile_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  


    return (
        <div className="property-location detail-office-map" data-aos="fade-up">
            <Container>
                <Row>
                    {props.mapimage !=null &&
                    <Col lg="7" id="map" className="order-lg-1 map-holder">
                        <div >
                            <LocRatingMap data={mapItems} />
                        </div>
                    </Col>
                    }
                    {props.mapimage ==null &&
                    <Col lg="12" id="map" className="order-lg-1 map-holder full-map">
                        <div >
                            <LocRatingMap data={mapItems} />
                        </div>
                    </Col>
                    }
                    {props.Areaguide !=null &&
                    <Col lg="5" id="video">
                        <Video src={props.Areaguide.Embed_Video_URL} image={image_url} />
                        {props.Areaguide !=null &&
                        <small>Located in {props.Areaguide.Title}, London <span><Link to={`/about/area-guide/${props.Areaguide.URL}/`}>Read Area Guide</Link></span></small>
                        }
                    </Col>
                    }
                </Row>
            </Container>
        </div>
    )
}

export default PropertyLocation
