import React, { useContext, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { useLocation } from "@reach/router"

import { Twitter, Facebook, Instagram, Linkedin, ArrowDown } from '@components/icon/icon'

import './styles/_index.scss';

const SocialShare = (props) => {
    const location = useLocation();
	return (
	<div className="social-share">
	<Container>
	<hr />
	<h3>Share this Post</h3>
	<ul>
	<li><a href={`https://www.facebook.com/sharer?u=${location.href}`} target="_blank"><span>Facebook</span> <Facebook /></a></li>
	<li><a href={`https://twitter.com/intent/tweet?url=${location.href}`} target="_blank"><span>Twitter</span> <Twitter /></a></li>
	<li><a href="https://www.instagram.com/anthonypepeco/" target="_blank"><span>Instagram</span> <Instagram /></a></li>
	<li><a href={`https://www.linkedin.com/cws/share?url=${location.href}`} target="_blank"><span>Linkedin</span> <Linkedin /></a></li>
	</ul>
  	</Container>
	</div>
	)
}
export default SocialShare
