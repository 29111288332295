import React from 'react';

/**
 * Assets
 */
import './styles/_index.scss';

const Sideabar = ({children}) => {
    return (
        <div className="sidebar">
            {children}
        </div>
    )
}

export default Sideabar
