import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { useLocation } from "@reach/router"
import "animate.css/animate.min.css";

/** 
* Assets
*/
import './styles/_index.scss';

/**
* Components
*/

import { Heart, Share } from '@components/icon/icon';
import StickyNavArea from '@components/sticky-nav-area/sticky-nav-area'

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
}
    from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share"

const PropertyFixedBar = (props) => {
    const location = useLocation();
    const shareUrl = location.href
    const [ref, inView] = useInView({
        threshold: 0
    });
    const [Shareicons, setShareicons] = React.useState(false);
    //$('.hover-share').hide();
    const openShareicons = () => {
        setShareicons(!Shareicons)
    }
    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    return (

        <div className="outer-holder" ref={ref}>
            <div className={classNames('property-fixed-bar d-none d-lg-block', { 'is-active': !inView })}>
                <Container>
                    <Row className="justify-content-between">
                        <Col className="d-none d-lg-block">
                            <h4>{props.Title}</h4>
                            <StickyNavArea links={props.data} />
                        </Col>
                        <Col md="12" lg="auto">
                            <div className="fixed-utils">
                                <a href="javascript:;" className="white-btn d-none d-lg-flex" onClick={openShareicons}>
                                    <Share />
                                    {Shareicons &&
                                    <div className="hover-share animate__animated animate__fadeIn">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                        </FacebookShareButton>
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                        </LinkedinShareButton>
                                        <WhatsappShareButton  onClick={()=>trackerShare('WhatsappShareButton')} url={shareUrl} className="my-share-button whatsapp-share">
                                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                        </WhatsappShareButton>
                                    </div>
                                    }
                                </a>

                                {props.office && props.office[0] &&
                                <Link to={`/contact/our-offices/${props.office[0].URL}/`} className="btn">Contact Local Office</Link>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PropertyFixedBar
